export default {
    name: "neo-note",
    props: {
        title: String,
        desc: String,
        allowEdit: {
            type: Boolean,
            default: true,
        },
        plain: {
            type: Boolean,
            default: false,
        },
        currentTab: String,
        customClass: String,
    },
    data() {
        return {
            isEdit: false,
            textAreaHeight: 0,
            descText: "",
        };
    },
    computed: {
        plainEdit() {
            return !this.currentTab == "keyFacts" || this.plain;
        },
    },
    mounted() {
        this.descText = this.desc;
    },
    methods: {
        onEdit() {
            if (!this.plainEdit) this.$emit("edit");
            else {
                this.textAreaHeight = this.$refs.textspan.getBoundingClientRect().height + 5;
                this.isEdit = true;
            }
        },
        auto_grow() {
            this.$refs.textarea.style.height = "0px";
            this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
        },
        onSave() {
            this.isEdit = false;
            this.$emit("change", this.descText);
        },
    },
};
